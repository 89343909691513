<template>
  <div id="app">
    <div class="header">
      <headTop ref="headd" v-if="isRouterAlive && !secHeadtop"></headTop>
    </div>
    <div class="cottent">
      <router-view v-if="isRouterAlive" />
    </div>
    <div class="footer">
      <div class="footer_inner">
        <bottom v-if="isRouterAlive"></bottom>
      </div>
    </div>
  </div>
</template>
<script>
import headTop from "./components/headTop.vue";
import bottom from "./components/bottom.vue";
import axios from './api/axios'
import Cookies from 'js-cookie'
export default {
  name: "App",
  components: {
    headTop,
    bottom,
  },
  data() {
    return {
      isRouterAlive: true,
      routepath: '',
      secHeadtop: '',
      //  spceilRouter:['/','storePage','goodsDeil','secstorePage','register','findBack','homePage'],
      spceilRouter: ['/payView', '/cartView', '/buyTab', '/userDetail'],
    }
  },
  provide() {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val)
        var wxCode = window.localStorage.getItem("wxCode");
        if (wxCode) {
          this.checkTokennn();
        }
        var usertoken = window.localStorage.getItem('usertoken') || '';
        if (usertoken != '' && val.path.indexOf('/loginn') == -1) {
          this.checkToken();
        }
        if (localStorage.getItem("locale") == 'en') {
          document.title = val.meta.title2;
        } else {
          document.title = val.meta.title;
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (this.spceilRouter.indexOf(val.path) != -1) {
          this.routepath = '';
          var usertoken = window.localStorage.getItem('usertoken') || '';
          if (usertoken == '') {
          }
        }
        else {
          this.routepath = 'specailView';
        }
        if (val.path == '/secstorePage') {
          this.secHeadtop = true;
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  created() {
    var usertoken = window.localStorage.getItem('usertoken') || '';
    //console.log(this.$route.path);///solve
    if (this.spceilRouter.indexOf(this.$route.path) != -1) {
      /* if(usertoken!=''&&this.$route.path.indexOf('/loginn')==-1){
        this.checkToken();
           console.log('checkToken验证created');
      } */
      this.routerChange();
    }
    if (this.$isphone()) {
      let scale = 1.0 / window.devicePixelRatio;
      let text = `<meta name="viewport" content="width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}, user-scalable=no">`;
      document.write(text);
    }
    var usertoken = window.localStorage.getItem('usertoken') || '';
    if (this.spceilRouter.indexOf(this.$route.path) != -1) {
      this.routepath = 'specailView';
    }
    // if(usertoken==''&&this.routepath!='specailView'&&this.$route.params.lgin!='1'){
    if (usertoken == '' && this.routepath != 'specailView') {
      this.$notify({
        title: this.$t('about.logged'),
        type: "warning",
      });
      // this.$router.push({ path: "/loginn" });
    }
  },
  mounted() {
    window.callCofimBox = this.cofimBox;
  },
  methods: {
    checkTokennn() {
      var url = '/reach/portal/api/user/getUserInfo'
      axios.get(url).then(response => {
        if (response.data.code == 1) {
          if (response.data.data.username) {

          }
          else {
            this.$router.push({ path: "/wXuserDetail" });
          }
        }
        else {
        }
      })
    },
    checkToken() {
      var url = '/reach/portal/api/user/getUserInfo'
      //区别刷新时候结束，不用往下执行
      axios.get(url).then(response => {
        if (response.data.code == 2) {
          this.$my_confirm(this.$t('Cart.expired'), this.$t('login.prompt'), {
            type: "warn",
            cancelBtnText: this.$t('login.sure'),
            otherBtnText: "",
            yesBtnText: ""
          })
            .then(action => {
              if (action == "yes") {
                this.$router.push({ path: "/loginn" });
              } else if (action == "other") { }
            })
            .catch(() => { });
          Cookies.set('token', null)
          window.localStorage.setItem('curretUser', '')
          window.localStorage.setItem('usertoken', '')
          this.reload();
        }
      })
    },
    routerChange() {
      this.$router.beforeEach((to, from, next) => {
        var usertoken = window.localStorage.getItem('usertoken') || '';
        if (this.spceilRouter.indexOf(to.path) != -1) {
          if (usertoken == '') {
            this.$my_confirm(this.$t('about.logged'), this.$t('login.prompt'), {
              type: "warn",
              cancelBtnText: this.$t('login.sure'),
              otherBtnText: "",
              yesBtnText: ""
            })
              .then(action => {
                if (action == "yes") {
                  this.$router.push({ path: "/loginn" });
                } else if (action == "other") {
                  console.log("点击了其他按钮");
                }
              })
              .catch(() => {
                //点取消
                console.log("点击了取消按钮cancel");
              });
            next(false)
          }
          else {
            next()
          }
        }
        else {
          next()
        }
      })
    },
    reload() {
      this.isRouterAlive = false;            //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true;         //再打开
      })
    },
    cofimBox() {
      this.$my_confirm(this.$t('about.logged'), this.$t('login.prompt'), {
        type: "warn",
        cancelBtnText: this.$t('login.sure'),
        otherBtnText: "",
        yesBtnText: ""
      })
        .then(action => {
          if (action == "yes") {
            this.$router.push({ path: "/loginn" });
          } else if (action == "other") {
            console.log("点击了其他按钮");
          }
        })
        .catch(() => {
          //点取消
          console.log("点击了取消按钮cancel");
        });

    }
  }
};
</script>
<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  /deep/ .el-button {
    span {
      font-size: 0.23rem;
    }

    &:hover {
      background-color: #cacaca;
      border-color: #cacaca;
    }
  }
}

.viewer-navbar {
  opacity: 0 !important;
}

#nav {
  @include font_size($font_medium);
  padding: 30px;
  width: 100px;
  height: 100px;
  background-color: #42b983;
  transform: translate(100px, 200px);

  a {
    font-weight: bold;
    color: #0c0c0c;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/*全局滚动条样式*/
::-webkit-scrollbar {
  width: 5px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  /* background-color: #01c8dc;
border-radius: 3px; */
  background-color: #ff0000;
}

/*全局滚动条样式结束*/
.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  min-width: 550Px;
}

.footer {
  width: 100%;
  margin-top: 74px;

  .footer_inner {
    width: 71%;
    margin: 0 auto;
  }
}
</style>
<style>
/* 模块标题 */
.staticBanner {
  width: 100%;
  height: 100%;
}

.huanH {
  white-space: nowrap;
}

.altitle {
  font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
  font-weight: 250;
  font-style: normal;
  font-size: 0.4rem;
  margin-top: 0.5rem;
}

.enter {
  white-space: pre-wrap;
}

.cursor {
  cursor: pointer;
}

/*  this.$notify的样式*/
.el-notification {
  width: 3.5rem !important;
}

.el-notification__icon {
  height: 0.5rem !important;
  width: 0.5rem !important;
  font-size: 22Px !important;
}

.el-notification__title {
  font-size: 14Px !important;
}

.el-cascader-node {
  height: 0.5rem !important;
  line-height: 0.3rem !important;
}

.el-popper[x-placement^=bottom] {
  width: 6rem !important;
}

.el-cascader-node__label {
  width: 2.8rem !important;
}

.el-cascader-menu__wrap {
  height: 4rem !important;
  width: 2rem !important;
}

.el-cascader-menu {
  min-width: 1.6rem !important;
}

.el-message-box__container .el-message-box__status {
  font-size: 0.65rem !important;
}

.el-message-box__btns .el-button--default {
  /* padding: 0.2rem 0.2rem !important; */
  font-size: 0.25rem !important;
}

.el-message-box__btns {
  text-align: center !important;
}

.el-message-box p {
  font-size: 0.5rem !important;
}

.el-message-box__message p {
  line-height: 0.6rem !important;
}

.pre {
  white-space: pre;
}
</style>
