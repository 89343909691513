<template>
  <div class="headAll">
    <div :class="ipadpro ? 'head_ipadpro' : !scaleShow ? 'head_mini' : 'head_wrap'">
      <div class="top_left" @click="toHome()" :class="scaleShow ? '' : 'scaleLeft'">
        <!-- <img src="../assets/logo333.png" />  -->
        <img :src="logoUrl" />
        <div :class="hiddLogoLine ? 'hiddLogoLine' : 'leftBottom'"></div>
        <!-- <i class="logo_icon"></i> -->
      </div>
      <div class="top_right" :class="scaleRightt ? 'scaleRight' : scaleShow ? '' : 'scaleShoww'">
        <!-- <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#fff" text-color="#000" active-text-color="#ffd04b" :router="true">-->
        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" text-color="#3C3C3C"
          active-text-color="#1fbaf3" :router="true" v-if="scaleShow" @mouseenter.native="maxmouseover()"
          @mouseleave.native="maxmouseout()">
          <el-menu-item @mouseenter.native="mouseover(0)" @mouseleave.native="mouseout()"
            :class="scaleItem ? 'scaleItemm' : ''">{{ $t("heard.Products") }}</el-menu-item>
          <el-menu-item @mouseenter.native="mouseover(1)" @mouseleave.native="mouseout()"
            :class="scaleItem ? 'scaleItemm' : ''">{{ $t("heard.Solution") }}</el-menu-item>
          <el-menu-item index="developer" :class="scaleItem ? 'scaleItemm' : ''" class="dev_icon"
            @mouseenter.native="mouseover(7)">
            <img src="../assets/dev.png" class="icon" />
            {{ $t("heard.Developer") }}</el-menu-item>
          <!--
          <el-menu-item
            @click="toSmart"
            class="Intel_icon"
            @mouseenter.native="mouseover(2)"
            @mouseleave.native="mouseout()"
          >智能开发平台</el-menu-item>
          -->
          <el-menu-item @click="toSmart" @mouseleave.native="mouseout()">{{ $t("heard.Intelligent") }}</el-menu-item>
          <el-menu-item @mouseenter.native="mouseover(3)" @mouseleave.native="mouseout()"
            :class="scaleItem ? 'scaleItemm' : ''" index="/storePage">{{ $t("heard.buy") }}</el-menu-item>
          <el-menu-item index="/helpCenter" @mouseenter.native="mouseover(4)" @mouseleave.native="mouseout()"
            :class="scaleItem ? 'scaleItemm' : ''">{{ $t("heard.support") }}</el-menu-item>
          <el-menu-item index="cooperation" @mouseenter.native="mouseover(5)" @mouseleave.native="mouseout()"
            :class="scaleItem ? 'scaleItemm' : ''">{{ $t("heard.Cooperation") }}</el-menu-item>
          <el-menu-item index="/reach" @mouseenter.native="mouseover(6)" @mouseleave.native="mouseout()">{{
      $t("heard.About") }}</el-menu-item>
        </el-menu>
        <el-menu class="el-menu-right" mode="horizontal" @select="handleSelect" background-color="#fff"
          text-color="#000" active-text-color="#ffd04b" :router="true">
          <!-- <span style="position: absolute;right: 92px;top: 43px;z-index: 200;">简体中文</span> -->

          <!-- 当前选中语言 -->
          <el-menu-item @click.native="openlang" class="itemNobtm" :class="scaleShow ? 'langText' : 'langText_mini'"
            style="position: relative; margin-right: 20px;">
            <p v-if='value == item.languageValue' v-for="(item, index) in languageArr" :key="index">
              {{ item.languageLabel }}
            </p>
            <i class="el-icon-arrow-down"></i>
          </el-menu-item>

          <!-- 语言列表 -->
          <div v-show="showlang" class="langau_wrap2" :class="scaleShow ? 'langau_pc' : 'langau_mini'"
            @mouseenter="langauBox()" @mouseleave="outlangauBox()">
            <span v-for="(item, index) in languageArr" :key="index" @click="changeType(item.languageValue)">{{
      item.languageLabel }}</span>
          </div>

          <!-- <el-submenu class="el-thesubmenu" index="b" @click.native="openlang">
            <template slot="title" v-if="false"></template>
<el-menu-item index="2-1" class="secitem" v-if="false">简体中文</el-menu-item>
<el-menu-item index="2-2" class="secitem" v-if="false">English</el-menu-item>
</el-submenu> -->

          <!-- new -->
          <!-- <el-submenu index="b">
            <template slot="title">
              <i class="sel-lang" v-if='value == item.languageValue' v-for="(item, index) in languageArr" :key="index">
                {{ item.languageLabel }}
              </i>
            </template>
            <el-menu-item v-for="(item, index) in languageArr" :key="index" @click="changeType(item.languageValue)">{{
      item.languageLabel }}</el-menu-item>
          </el-submenu> -->


          <el-menu-item class="itemNobtm">
            <div v-if="(usertoken == '' || usertoken === null) && curUser == ''" class="tologin"
              :class="scaleShow ? '' : 'tologin_mini'">
              <span @click.stop="toLogi()">{{ $t("heard.Login") }}/</span>
              <span @click.stop="toReg()">{{ $t("heard.register") }}</span>
            </div>
            <div>
              <i class="curUser" @mouseenter="userBox()" @click="clickUserBox()">{{ curUser }}</i>
            </div>
          </el-menu-item>

          <div v-show="showUserbox" class="userSelect_wrap" :class="scaleShow ? 'langau_pc' : 'langau_mini'"
            @mouseleave="outUserBox()">
            <div @click.stop="toChangepwd" v-if="!isIphone && showUdpsd"><span>{{ $t("heard.information") }}</span></div>
            <div @click.stop="toCart(1)"><span>{{ $t("heard.Purchased") }}</span></div>
            <div @click.stop="toCart(2)"><span>{{ $t("heard.shopping") }}</span></div>
            <div @click.stop="exit"><span>{{ $t("heard.quit") }}</span></div>

          </div>

          <el-menu-item class="itemNobtm" @click="openSerch">
            <i class="serch_icon"></i>
          </el-menu-item>
          <el-menu-item class="itemNobtm" v-if="!scaleShow" @click.native="openMinmemu">
            <i class="toggle"></i>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
    <transition name="show" tag="span">
      <div class="top-box-view">
        <div class="topBox" v-show="showMenubox" @mouseenter="mouseoverBox()" @mouseleave="mouseoutBox()">
          <!-- <topMenubox :curindex="sendindex" ref="menubox"></topMenubox> -->
          <topMenubox ref="menubox"></topMenubox>
        </div>
      </div>
    </transition>
    <transition name="show" tag="span">
      <ul v-if="miniMenu" class="minmenuWrap" :class="!isIphone && (!scaleShow || ipadpro) ? 'minmenu_ipad' : ''">
        <li>
          <a @click="toMininPath('产品与服务')">{{ $t("heard.Products") }}</a>
        </li>
        <li>
          <a @click="toMininPath('解决方案')">{{ $t("heard.Solution") }}</a>
        </li>
        <li>
          <a class="min_devicon" @click="toMininPath('开发者中心')">{{ $t("heard.Developer") }}</a>
        </li>
        <li>
          <a class="intel_devicon" @click="toMininPath('智能开发平台')">{{ $t("heard.Intelligent") }}</a>
        </li>
        <li>
          <a @click="toMininPath('RIV商城')">{{ $t("heard.buy") }}</a>
        </li>
        <li>
          <a @click="toMininPath('帮助支持')">{{ $t("heard.support") }}</a>
        </li>
        <li>
          <a @click="toMininPath('合作与生态')">{{ $t("heard.Cooperation") }}</a>
        </li>
        <li>
          <a @click="toMininPath('关于我们')">{{ $t("heard.About") }}</a>
        </li>
      </ul>
    </transition>
    <topSearch v-if="showSerch"></topSearch>
  </div>
</template>
<script>
import topMenubox from "./topMenubox/topMenubox.vue";
import topSearch from "./serchView/topSearch.vue";
import axios from "../api/axios";
import Cookies from "js-cookie";
export default {
  name: "headTop",
  components: {
    topMenubox,
    topSearch,
  },
  inject: ["reload"],
  watch: {
    type(newVal, oldVal) {
      this.value = newVal
    },
    // 监听,当路由发生变化的时候执行
    $route: {
      handler: function (val, oldVal) {
        if (val.path == "/userDetail") {
          this.showUdpsd = false;
        } else {
          this.showUdpsd = true;
        }
        this.getToken();
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: '',
      type: '',
      hiddLogoLine: false,
      logoUrl: "",
      languageArr: [],
      curUser: "",
      wxCode: "",
      showUdpsd: true, // 在用户中心不显示修改密码
      usertoken: "",
      showSerch: false,
      isIphone: false,
      // showchangepsd:false,判断一个量即可
      activeIndex: 0,
      showMenubox: false,
      showlang: false,
      showUserbox: false,
      scaleItem: false,
      scaleRightt: false,
      scaleShow: true,
      miniMenu: false,
      ipadpro: false,
      outTag: false, // 判断mouseoutBox是否触发，解决动画问题
      boxIn: false,
      language: true,
    };
  },
  /* created() {
      this.usertoken=window.localStorage.getItem('usertoken')||'';
  }, */
  mounted() {
    this.value = localStorage.getItem('locale') || 'zh'
    this.getToken();
    // 等待dom渲染完毕再加载
    this.$nextTick(() => {
      var p = navigator.platform;
      const mac = p.indexOf("Mac") === 0;
      const x11 = p === "X11" || p.indexOf("Linux") === 0;
      // 判断当前系统
      if (p.indexOf("Win") === 0 || mac || x11) {
        // 监听缩放
        window.addEventListener("resize", () => {
          this.commonSySize();
        });
        // 监听页面加载
        window.addEventListener("load", () => {
          this.commonSySize();
        });
      }
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.isIphone = true;
        this.scaleShow = false;
      } else {
        this.isIphone = false;
      }
    });
    this.getLogo();
  },
  methods: {
    toCart(e) {
      if (e == 1) {
        this.$router.push({ path: "/buyTab" });
      } else {
        this.$router.push({ path: "/cartView" });
      }
    },
    getLogo() {
      this.commonSySize();
      var url = "/reach/portal/api/homepage/getLogo";
      axios.post(url, {}, {
        params: {
          language: localStorage.getItem('locale')
        }
      }).then((response) => {
        if (response.data.code === 1) {
          this.logoUrl = response.data.data.logoUrl;
        }
      });
      // 获取语言列表
      var url = "/reach/portal/api/homepage/getLanguages";
      axios.get(url).then((response) => {
        console.log(response)
        if (response.data.code === 1) {
          this.languageArr = response.data.data;
        }
      })
    },
    toMininPath(e) {
      if (e == "产品与服务") {
        this.$router.push({ path: "/produceSeries" });
      }
      if (e == "解决方案") {
        this.$router.push({ path: "/solve" });
      }
      if (e == "开发者中心") {
        this.$router.push({ path: "/developer" });
      }
      if (e == "智能开发平台") {
        this.toSmart();
      }
      if (e == "RIV商城") {
        this.$router.push({ path: "/storePage" });
      }
      if (e == "帮助支持") {
        this.$router.push({ path: "/helpCenter" });
      }
      if (e == "合作与生态") {
        this.$router.push({ path: "/cooperation" });
      }
      if (e == "关于我们") {
        this.$router.push({ path: "/reach" });
      }
      this.miniMenu = !this.miniMenu;
    },
    toSmart() {
      // window.open('http://172.16.10.115:9000/smart')
      if (this.usertoken === '' && this.curUser === '') {
        this.$message.warning(this.$t("heard.log"))
        this.toLogi()
      } else {
        window.open("/smart");
      }
    },
    getToken() {
      this.usertoken = window.localStorage.getItem("usertoken") || "";
      this.curUser = window.localStorage.getItem("curretUser") || "";
      this.wxCode = window.localStorage.getItem("wxCode") || "";
      this.getUserInfo();
    },
    getUserInfo() {
			var url = '/reach/portal/api/user/getUserInfo'
			axios.get(url).then(res => {
				// console.log('user info:', res.data)
        if(res.data.code === 1) {
          // 用户昵称
          const nickname = res.data.data.nickname;
          if(nickname !== '') {
            // 如果昵称不为空则显示昵称，昵称为空显示登录用户名
            this.curUser = nickname;
          }
        }
			})
		},
    // 公共使用控制页面大小收缩
    commonSySize() {
      const idTag = document.body;
      console.log("屏幕大小：" + idTag.offsetWidth);
      if (idTag.offsetWidth >= 1440) {
        this.scaleItem = false;
      }
      if (idTag.offsetWidth < 1440 && idTag.offsetWidth > 1110) {
        this.scaleItem = true;
        this.scaleRightt = true;
      }
      if (idTag.offsetWidth <= 1320) {
        this.scaleRightt = false;
        this.scaleShow = false;
        this.showMenubox = false;
      } else {
        this.scaleShow = true;
        this.miniMenu = false;
        // console.log("max"+idTag.offsetWidth);
      }
      if (new RegExp("ipad", "i").test(window.navigator.userAgent)) {
        // 获取代理头信息
        this.scaleRightt = false;
        this.scaleShow = false;
        this.ipadpro = true;
      }
    },
    openlang() {
      this.showlang = !this.showlang;
    },
    handleSelect(key, keyPath) {
      console.log(key);
    },
    toHome() {
      this.$router.push({
        // name: "storePage",
        name: "homePage",
      });
    },
    langauBox() {
      this.showlang = true;
    },
    outlangauBox() {
      this.showlang = false;
    },
    clickUserBox() {
      this.$router.push({ name: "userDetail" });
    },
    userBox() {
      if (this.usertoken == "" && this.curUser == "") {
        this.showUserbox = false;
      } else {
        this.showUserbox = !this.showUserbox;
      }
    },
    outUserBox() {
      this.showUserbox = false;
    },
    mouseoverBox() {
      this.boxIn = true;
      if (this.outTag) {
        setTimeout(function () {
          this.showMenubox = true;
          this.outTag = false;
        }, 2000);
      } else {
        this.showMenubox = true;
      }
    },
    mouseoutBox() {
      this.showMenubox = false;
      this.outTag = true;
      this.boxIn = false;
    },
    mouseover(e) {
      // console.log("fa11:"+e);
      // this.sendindex=e;
      // this.$refs.menubox.curindexx = e;
      // this.$refs.menubox.boxList[e]=true;
      // this.$refs.menubox.boxList.forEach((v)=>{v=false});
      this.hiddLogoLine = true;
      this.activeIndex = e;
      this.showMenubox = false;
      var that = this;
      if (e == 7) {
        that.showMenubox = false;
      } else {
        setTimeout(function () {
          that.showMenubox = true;
          that.$refs.menubox.boxList.forEach(function (item, index) {
            that.$refs.menubox.boxList[index] = false;
            // item=false;
            // console.log("fa:" + item+index + that.$refs.menubox.boxList[index]);
          });
          // that.$refs.menubox.boxList[e]=true;
          that.$set(that.$refs.menubox.boxList, e, true);
        }, 300);
      }
    },
    mouseout() {
      this.hiddLogoLine = false;
      // that.showMenubox = true;
      // this.showMenubox = this.boxfocus == true ? true : false;
      // that.showMenubox=false;
      /* 	this.$refs.menubox.boxList.forEach(function(item, index) {
            // that.$refs.menubox.boxList[index]= false;
            // item=false;
            that.$set(that.$refs.menubox.boxList,index,false);
            // console.log("fa:" + item+index + that.$refs.menubox.boxList[index]);
          }); */
    },
    maxmouseover() { },
    maxmouseout() {
      var that = this;
      setTimeout(function () {
        if (!that.boxIn) {
          that.showMenubox = false;
        }
      }, 600);
    },
    openSerch() {
      this.showSerch = !this.showSerch;
    },
    openMinmemu() {
      this.miniMenu = !this.miniMenu;
    },
    toChangepwd() {
      this.$router.push({
        name: "userDetail",
        params: {
          changepwd: "1",
        },
      });
    },
    exit() {
      var url = "/reach/portal/api/user/logout";
      axios.post(url);
      // 退出登录清空token
      Cookies.set("token", null);
      window.localStorage.setItem('curretUser', null);
      window.localStorage.setItem('usertoken', null);
      this.showUserbox = false;
      this.$router.push({ path: "/loginn" });
      // this.$router.push({ path: "/loginn",params: {isRegis:"2"} });
      // this.$router.push({ path: "/loginn",query:{isRegis:"2"} });
    },
    toReg() {
      this.$router.push({ path: "/register" });
    },
    toLogi() {
      this.$router.push({ name: "loginn", params: { lgin: "1" } });
      // this.$router.push({ path: "/loginn",query:{isRegis:"1"} });
      /*  var that = this;
        setTimeout(function () {
              that.$router.push({name:'loginn',params:{lgin:'1'} });
            }, 2000); */
    },
    changeType(type) {
      console.log(type)
      // console.log(this.$route)
      if (type == 'en') {
        document.title = this.$route.meta.title2;
      } else {
        document.title = this.$route.meta.title;
      }
      // console.log(localStorage.getItem('routerId').split('_')[0])
      // 产品模块参数
      if (type == 'zh' && localStorage.getItem('routerId') != null) {
        localStorage.setItem('routerId', localStorage.getItem('routerId').split('_')[0])
      } else if (localStorage.getItem('routerId') != null) {
        localStorage.setItem('routerId', localStorage.getItem('routerId').split('_')[0] + "_" + type)
      }
      // 服务列表参数
      if (type == 'zh' && localStorage.getItem('serviceTypeId') != null) {
        localStorage.setItem('serviceTypeId', localStorage.getItem('serviceTypeId').split('_')[0])
      } else if (localStorage.getItem('serviceTypeId') != null) {
        localStorage.setItem('serviceTypeId', localStorage.getItem('serviceTypeId').split('_')[0] + "_" + type)
      }
      // 解决方案参数
      if (type == 'zh' && localStorage.getItem('solutionTypeId') != null) {
        localStorage.setItem('solutionTypeId', localStorage.getItem('solutionTypeId').split('_')[0])
      } else if (localStorage.getItem('solutionTypeId') != null) {
        localStorage.setItem('solutionTypeId', localStorage.getItem('solutionTypeId').split('_')[0] + "_" + type)
      }
      // 商城参数
      if (type == 'zh' && localStorage.getItem('productId') != null) {
        localStorage.setItem('productId', localStorage.getItem('productId').split('_')[0])
      } else if (localStorage.getItem('productId') != null) {
        localStorage.setItem('productId', localStorage.getItem('productId').split('_')[0] + "_" + type)
      }
      if (type == 'zh' && localStorage.getItem('id') != null) {
        localStorage.setItem('id', localStorage.getItem('id').split('_')[0])
      } else if (localStorage.getItem('id') != null) {
        localStorage.setItem('id', localStorage.getItem('id').split('_')[0] + "_" + type)
      }
      // 商城参数2
      // if (type == 'zh' && localStorage.getItem('productIds') != null) {
      //   localStorage.setItem('productIds', localStorage.getItem('productIds').split('_')[0])
      // } else if (localStorage.getItem('productIds') != null) {
      //   localStorage.setItem('productIds', localStorage.getItem('productIds').split('_')[0] + "_" + type)
      // }
      // if (type == 'zh' && localStorage.getItem('ids') != null) {
      //   localStorage.setItem('ids', localStorage.getItem('ids').split('_')[0])
      // } else if (localStorage.getItem('ids') != null) {
      //   localStorage.setItem('ids', localStorage.getItem('ids').split('_')[0] + "_" + type)
      // }
      localStorage.setItem("locale", type);
      this.type = localStorage.getItem('locale')
      // this.$store.commit('udpateType', type)
      this.reload()
      // 修改显示语言
      this.$i18n.locale = type;
    },
    // },

  },
};
</script>
<style>
.lang-menu {
  /* width: 100px; */
  margin-right: 20px;
}

.sel-lang {
  font-size: 18px !important;
}

* {
  margin: 0;
  padding: 0;
}

.el-menu-right .el-submenu__title {
  font-size: 13px !important;
  color: #939393 !important;
  padding: 0;
  margin-left: 0.09rem;
}

.el-menu-right .el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 104px !important;
  position: absolute;
  top: -6px;
  /* display: none; */
}

.top_right .el-menu-item {
  height: 100% !important;
}

.top_right .el-menu-item:after {
  content: " ";
  position: absolute;
  z-index: 2;
  bottom: 10% !important;
  left: 0;
  display: block;
  width: 165px;
  height: 0px;
}

.top_right .el-menu-item:hover {
  color: #1fbaf3 !important;
}

.top_right .itemNobtm:hover {
  background-color: rgb(255 255 255 / 0%) !important;
}

.top_right .itemNobtm:hover:after {
  height: 0px !important;
}

.top_right .el-menu-item:hover:after {
  height: 0.09rem;
  animation: ad_width 0.5s linear forwards;
  background: #ffde5d;
}

.top_right .el-menu--horizontal>.el-submenu:focus .el-submenu__title,
.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
  background-color: rgb(255 255 255 / 0%) !important;
}

.top_right .el-menu--horizontal>.el-submenu:focus .el-submenu__title,
.el-menu--horizontal>.el-submenu:hover .el-icon-arrow-down:before {
  color: #1fbaf3;
}

@keyframes ad_width {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
.scaleLeft {
  width: 1.5rem !important;
  line-height: 0.7rem;
}

.scaleRight {
  // padding-left: 0.92rem !important;
}

.scaleShoww {
  justify-content: flex-end !important;
  padding-left: 1rem !important;
}

.scaleItemm {
  padding: 0 0.2rem !important;
  height: 100%;
}

.el-menu-right {
  position: relative;
  padding-right: 0rem;
}

.el-thesubmenu {
  position: relative;
}

.el-menu-right .userSelect_wrap {
 // display: flex;
  //flex-direction: column;
  position: absolute;
  left: 70px;
  z-index: 200;
  width: 1.8rem;
  background-color: transparent;

  div{
    background-color:#fff ;
    line-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 0.1rem;
    min-height:0.9rem;
  }
  span{
    display: inline-block;
    @include font_size($font_samll);
    text-align: center;

  }
}

.el-menu-right .langau_wrap2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  z-index: 200;
  // border: 1px solid #000000;
  min-width: 1.2rem;

}

.el-menu-right .langau_pc {
  top: 1.3rem;
  background-color: transparent;
  padding-top: 0.1rem;

}

.el-menu-right .langau_mini {
  top: 1rem;

}
.el-menu-right .langText_mini span {
  font-size: 26px !important;
}

.el-menu-right .langau_wrap2 span {
  height: 0.7rem;
  @include font_size($font_samll);
  background-color: rgb(255, 255, 255);
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 0.1rem;
}

.el-menu-right .langau_mini span {
  padding: 0.5rem 0 0.5rem 0;
}

.el-menu-right .userSelect_wrap div {
  border-bottom: 1px solid #d7d7d7;
}

.el-menu-right .langau_wrap2 span {
  border-bottom: 1px solid #d7d7d7;
}

.el-menu-right .userSelect_wrap div:hover {
  background-color: #d7d7d7;
  color: #1fbaf3;
  cursor: pointer;
}

.el-menu-right .langau_wrap2 span:hover {
  background-color: #d7d7d7;
  color: #1fbaf3;
  cursor: pointer;
}

.el-menu--horizontal>.el-submenu .el-submenu__title {
  color: #939393;
}

.el-menu--horizontal .el-menu--popup-bottom-start .el-menu-item {
  font-size: 12px;
  text-align: center;
}

.el-menu-right>.el-submenu {
  height: 100%;
  display: flex;
  align-items: center;
}

.headAll {
  width: 100%;
  position: relative;
}

.head_wrap {
  width: 100%;
  display: flex;
  margin: 0 auto;
  position: relative;
}

.head_mini {
  width: 100%;
  padding: 0.15rem 0.15rem;
  display: flex;
  margin: 0 auto;
  position: relative;

  .top_left {
    img {
      width: 200px;
      cursor: pointer;
      position: relative;
      z-index: 5;
    }
  }

  .top_right {
    .el-menu-right {
      line-height: normal;
      height: 1rem;
    }

    /deep/ .el-menu-item {
      line-height: normal;
    }
  }
}

.head_wrap .top_left {
  width: 2.1rem;
  height: 1.3rem;
  position: relative;
  padding-left: 0.6rem;
  display: flex;
  align-items: center;
}

.head_wrap .leftBottom {
  width: 3.9rem;
  position: absolute;
  bottom: -2%;
  left: -18%;
  height: 0.1rem;
  background: #ffde5d;
}

.head_wrap .hiddLogoLine {
  background: #dfdbdb00;
}

@keyframes ad_width {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.head_wrap .logo_icon {
  position: relative;
}

.head_wrap .logo_icon:before {
  width: 100px;
  height: 100px;
  background-image: url(../assets/logo.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: 14px;
  top: 13px;
  background-size: contain;
}

.head_wrap .top_left img {
  width: 100%;
  cursor: pointer;
}

.top_right {
  padding-left: 1.5rem;
  display: flex;
  flex: 6;
  justify-content: space-between;
}

.el-header {
  background-color: #fff;
}

.el-menu--horizontal /deep/ .el-menu-item {
  height: 100%;
  border-bottom-color: rgb(255, 255, 255) !important;
  font-size: 0.3rem;
  display: flex;
  align-items: center;
}

.el-menu-item {
  padding: 0 0.18rem;
}

.el-menu-right .el-menu-item {
  padding: 0 0.046875rem;
  padding-right: 0.38rem;
}

.el-menu.el-menu--horizonta {
  background-color: rgb(84, 92, 100);
}

.el-menu--horizontal {
  border-bottom: none !important;
}

.dev_icon {
  position: relative;
  padding-left: 0.8rem;
}

.icon {
  // width: 0.4rem;
  height: 0.4rem;
  // background-image: url(../assets/dev.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  background-size: contain;
  margin-top: -25px;
  margin-left: -40px;
  position: relative;
  right: 5px;
}

// .dev_icon:before {
//   width: 0.4rem;
//   height: 0.4rem;
//   background-image: url(../assets/dev.png);
//   background-position: bottom;
//   background-repeat: no-repeat;
//   content: "";
//   position: absolute;
//   left: 17.5%;
//   top: 28%;
//   background-size: contain;
// }

.Intel_icon {
  position: relative;
  padding-left: 0.8rem;
}

.Intel_icon:before {
  width: 0.4rem;
  height: 0.4rem;
  background-image: url(../assets/inli_icon.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: 8.5%;
  top: 26%;
  background-size: contain;
}

.tologin {
  color: #939393;
  // font-size: 13px;
}

.tologin span:hover {
  color: #1fbaf3;
}

.tologin_mini {
  font-size: 27px;
}

.mine_icon {
  display: inline-block;
  width: 0.425rem;
  height: 0.4rem;
  background-image: url(../assets/login.png);
  background-size: 100% 100%;
}

.serch_icon {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  background-image: url(../assets/serch.png);
  /* background-position: center center;
background-repeat:no-repeat; */
  background-size: 100% 100%;
}

.serch_icon:hover {
  background-image: url(../assets/serch2.png);
}

.curUser {
  color: #1fbaf3;
  font-weight: 600;
}

.toggle {
  display: block;
  width: 0.5rem;
  border-top: 0.06452rem solid #939393;
  position: relative;
  color: #000000;
}

.toggle:before {
  content: "";
  width: 0.5rem;
  border-top: 0.06452rem solid #939393;
  position: absolute;
  top: -0.2rem;
  left: 0;
  color: #000000;
}

.toggle:after {
  content: "";
  width: 0.5rem;
  border-top: 0.06452rem solid #939393;
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  color: #000000;
}

.toggle:hover {
  display: block;
  border-color: #1fbaf3;
  position: relative;
  color: #1fbaf3;
}

.toggle:hover:before {
  content: "";
  border-color: #1fbaf3;
  color: #1fbaf3;
}

.toggle:hover:after {
  content: "";
  border-color: #1fbaf3;
  color: #1fbaf3;
}

.top-box-view {
  background-color: white;

  .topBox {
    width: 100%;
    position: absolute;
    top: 1.19rem;
    left: 0;
  }
}

.topBox:before {
  content: " ";
  position: absolute;
  z-index: 2;
  top: -1px;
  left: 0;
  display: block;
  /* width: 165px;
  height: 1px; */
  width: 100%;
  height: 7px;
  background: #dbdbdb;
  /* transform: translate(-50%); */
}

/*
.topBox:hover:before {
  height: 2px;
  animation: boxad_width 0.5s linear forwards;
  background: #dbdbdb;
}

 .head_wrap:hover + .topBox:before {
  height: 7px;
  animation: boxad_width 3s linear forwards;
  background: #dbdbdb;
}

@keyframes boxad_width {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
} */

/* vue动画 */
@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.show-enter-active {
  animation: show 0.6s;
}

.show-leave-active {
  animation: hide 0.3s;
}

.show-enter,
.show-leave-to {
  opacity: 0;
}

.minmenu_ipad {
  .min_devicon:before {
    width: 2.53rem;
    height: 0.7rem;
    left: -94%;
    top: -39%;
  }

  .intel_devicon:before {
    left: -68%;
    top: -39%;
    width: 2.53rem;
    height: 0.7rem;
  }
}

.minmenuWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 97%;
  left: 0;
}

.minmenuWrap li {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 0.05rem solid #cdcdcd;
  padding: 0.32rem 0 0.32rem 0;
}

.minmenuWrap li a {
  line-height: 0.48rem;
  font-size: 25px;
}

.min_devicon,
.intel_devicon {
  position: relative;
}

.min_devicon:before {
  background-image: url(../assets/dev.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 2.53rem;
  height: 0.7rem;
  left: -94%;
  top: -39%;
  background-size: contain;
}

.intel_devicon:before {
  background-image: url(../assets/inli_icon.png);
  background-position: bottom;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: -68%;
  top: -39%;
  width: 2.53rem;
  height: 0.7rem;
  background-size: contain;
}

.el-thesubmenu /deep/ .el-submenu__icon-arrow {
  margin-left: 0rem !important;
  margin-top: -0.03rem !important;
  margin-right: 0.4rem;
  @include font_size($font_samll);
}
</style>
<style scoped lang="scss">
.head_ipadpro {
  width: 100%;
  padding: 0.1rem 0.15rem;
  display: flex;
  margin: 0 auto;
  position: relative;

  .scaleLeft {
    width: 1.5rem !important;

    img {
      width: 80%;
    }
  }

  .el-menu-right .langau_mini {
    top: 0.5rem;

    span {
      padding: 0.3rem 0.1rem 0.3rem 0.1rem;
    }
  }
}

.langText {
  padding-right: 0 !important;
  @include font_size(9px);
  color: #939393 !important;
}

.langText_mini {
  padding-right: 0 !important;
  color: #939393 !important;
  font-size: 27px !important;
}

.langText:hover {
  color: #939393 !important;
}

@media only screen and (max-width: 1110px) {
  /*   .el-thesubmenu:after {
      content: "简体中文";
      position: absolute;
      right: 0.32rem;
      top: 0.16rem;
      z-index: 200;
      display: block;
      color: #939393;
      @include font_size($font_medium_s);
    } */

  .mine_icon {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-image: url(../assets/login.png);
    background-size: 100% 100%;
  }

  .serch_icon {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    background-image: url(../assets/serch.png);
    background-size: 100% 100%;
  }

  .toggle {
    display: block;
    width: 0.5rem;
    border-top: 0.06452rem solid #939393;
    position: relative;
    color: #1fbaf3;
  }

  .toggle:before {
    content: "";
    width: 0.5rem;
    border-top: 0.06452rem solid #939393;
    position: absolute;
    top: -0.201613rem;
    left: 0;
    color: #1fbaf3;
  }

  .toggle:after {
    content: "";
    width: 0.5rem;
    border-top: 0.06452rem solid #939393;
    position: absolute;
    bottom: -0.201613rem;
    left: 0;
    color: #1fbaf3;
  }
}

@media only screen and (max-width: 734px) {
  // .el-thesubmenu {
  //   /* padding-left: 2.5rem; */
  // }

  .el-thesubmenu:after {
    right: 1.4rem;
    top: 0.1rem;
  }

  .el-menu-right .langau_wrap {
    top: 1rem;
    left: 0rem;
  }
}

.top_right {
  /deep/ .el-menu-item {
    // line-height: 1.3125rem;
  }

  .el-menu-right {
    // line-height: 1.3125rem;
    // height: 1.3125rem;
  }
}

@media screen and (min-width: 1320px) and (max-width: 1424px) {
  .el-menu-right .el-menu-item {
    padding: 0 0.046875rem;
    padding-right: 0.1rem;
  }

  .el-menu-item {
    padding: 0rem;
  }
}
</style>
