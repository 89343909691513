<template>
  <div class="content_all">
    <div class="topWrap">
      <img :src="bannerImg">
      <p class="altitle">{{ $t('help.download') }}</p>
      <p class="sectitle">{{ $t('help.Select') }}</p>
      <el-select v-model=currentDeviceTypeOne :popper-append-to-body="false" placeholder=""
        popper-class="operateDropOption" @change=getCurrentSelected>
        <el-option v-for="item in options" :key="item.id" :label="item.typeName" :value="item">
        </el-option>
      </el-select>
      <div><el-button size="mini" icon="el-icon-search" @click="getDownloadList">{{ $t('help.check') }}</el-button></div>
    </div>
    <div class="content_inner">
      <div class="downloadWrap">
        <div class="leftWrap"><i class="fas fa-sort-up"></i><span>{{ this.currentDeviceType.typeName }}</span></div>
        <div class="newsRow">
          <el-row type="flex">
            <el-col>{{ $t('help.Document') }}<span>{{ $t('help.Update') }}</span></el-col>
            <el-col></el-col>
          </el-row>
          <el-row justify="space-space" type="flex" v-for="(item, index) in newsData.newsList" :key="index">
            <a @click="checkToken(item)">
              <el-col>
                <i class="far fa-file-alt"></i>{{ item.fileName }}
                <span>{{ item.createDate }}</span>
              </el-col>
              <el-col><i class="fas fa-download"></i></el-col>
            </a>
          </el-row>
        </div>
      </div>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
        :prev-text="$t('help.Previous')" :next-text="$t('help.Next')" layout="prev, pager, next" :page-size="6"
        :total="newsData.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from '../../api/axios'
export default {
  name: 'produceSeries',

  data() {
    return {
      bannerImg: '',
      currentPage: 1,
      currentDeviceType: {
        typeName: this.$t('help.devices')
      },
      currentDeviceTypeOne: '',
      input: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      newsData: {
        total: 30,
        newsList: [
          {
            title: '今天',
            createDate: '2021-11-01',
            newsLink: 'https://www.sogou.com/',
            dowLoadLink: 'https://www.baidu.com/'
          },
          {
            title: '明天',
            createDate: '2021-11-02',
            newsLink: 'https://www.sogou.com/',
            dowLoadLink: 'https://www.baidu.com/'
          },
          {
            title: '明天2',
            createDate: '2021-11-02',
            newsLink: 'https://www.sogou.com/',
            dowLoadLink: 'https://www.baidu.com/'
          },
          {
            title: '明天3',
            createDate: '2021-11-02',
            newsLink: 'https://www.sogou.com/',
            dowLoadLink: 'https://www.baidu.com/'
          },
          {
            title: '明天4',
            createDate: '2021-11-02',
            newsLink: 'https://www.sogou.com/',
            dowLoadLink: 'https://www.baidu.com/'
          }
        ]
      }
    }
  },
  watch: {
  },
  created() { },
  mounted() {
    this.getDeviceTypeList()
    this.getDownloadList()
    this.getBannerImg()
  },
  methods: {
    checkToken(parm) {
      console.log(parm)
      if(parm.fileName!=="官网注册和智能开发平台使用文档"){
          var url = '/reach/portal/api/user/getUserInfo'
          //区别刷新时候结束，不用往下执行
          axios.get(url).then(response => {
            if (response.data.code == 2) {
              this.$my_confirm(this.$t('Cart.expired'), this.$t('login.prompt'), {
                type: "warn",
                cancelBtnText: this.$t('login.sure'),
                otherBtnText: "",
                yesBtnText: ""
              })
                .then(action => {
                  if (action == "yes") {
                    this.$router.push({ path: "/loginn" });
                  } else if (action == "other") { }
                })
                .catch(() => { });
              Cookies.set('token', null)
              window.localStorage.setItem('curretUser', '')
              window.localStorage.setItem('usertoken', '')
              this.reload();
            }
            else {
              window.open(parm.filePath)
            }
          })
      }else{
        window.open(parm.filePath)
      }
    },
    getCurrentSelected(row) {
      this.currentDeviceType = row
      this.currentDeviceTypeOne = row.typeName
      this.getDownloadList()
    },
    getDeviceTypeList() {
      var url = '/reach/portal/api/support/getDeviceTypeList'
      axios.post(url, {}, {
        params: { language: localStorage.getItem("locale") }
      }).then(response => {
        if (response.data.code === 1) {
          this.options = response.data.data
        }
      })
    },
    getDownloadList() {
      var url = '/reach/portal/api/support/getDownloadList'
      axios.get(url, {
        params: {
          pageIndex: this.currentPage,
          pageSize: 6,
          deviceType: this.currentDeviceType.id,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          var downloadList = response.data.data.downloadList
          downloadList.forEach(item => {
            item.createDate = this.$timestampToTime(item.createDate)
          })
          this.newsData.newsList = downloadList
          this.newsData.total = response.data.data.total
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getDownloadList()
    },
    getBannerImg() {
      var bannerType = 'download'
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: bannerType,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code) {
          if (response.data.data.length > 0) {
            this.bannerImg = response.data.data[0].adImage
          }
        }
      })
    }
  }
}
</script>
<style scoped>
.operateDropOption .el-select-dropdown__item {
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.3rem;
}

.operateDropOption .el-scrollbar__bar.is-vertical {
  width: 0.2rem;
  top: 0.010417rem;
}
</style>
<style scoped lang="less">
.el-button {
  border: 0.05rem solid #dcdfe6;
  font-size: 0.26rem;
}

.content_all {
  padding-bottom: 2rem;

  .topWrap {
    margin-top: 1.5rem;
    padding-top: 0.5rem;
    height: 4.5rem;
    background: #e5edf0;
    background-size: cover;

    .el-select {
      width: 7rem;
      margin-bottom: 0.5rem;
      // /deep/ .el-input {
      //   font-size: 0.25rem;
      //   .el-input__inner {
      //       padding-left: 0.2rem;
      //       height: 0.55rem;
      //       line-height: 0.55rem;
      //   }
      //   .el-input__suffix{
      //         top: -0.16rem;
      //         right: 0.2rem;
      //         .el-select__caret{
      //           color: #000000;
      //         }
      //         .is-reverse{
      //           line-height: 0.9rem;
      //         }
      //       }
      // }
    }

    .el-button {
      // width: 2rem;
      // padding: 0.1rem 0;
      background-color: #35c68f;
      color: #fff;
      font-weight: 600;

      .el-icon-search {
        // margin-right: 0.2rem;
      }
    }

    p:nth-child(2) {
      margin: 0.2rem 0;
      font-size: 0.6rem;
    }

    .sectitle {
      font-size: 0.35rem;
      margin-bottom: 0.3rem;
    }
  }

  .content_inner {
    width: 70%;
    margin: 0 auto;

    .downloadWrap {
      display: flex;
      margin-top: 1rem;

      .leftWrap {
        border-right: 0.05rem #c8cbcd solid;
        margin-right: 0.3rem;

        .svg-inline--fa.fa-w-10 {
          transform: rotate(89deg);
          font-size: 0.5rem;
          color: #3ac0f1;
        }

        span {
          margin: 0 0.5rem 0 0.3rem;
          font-size: 0.3rem;
          vertical-align: super;
          white-space: nowrap;
        }
      }

      .newsRow {
        border: 1px #cfd7da solid;
        border-left: none;
        border-right: none;
        width: 85%;

        /deep/ .el-row {
          font-size: 0.3rem;
          background-color: #f0f8fa;
          height: 0.8rem;
          line-height: 0.8rem;

          a {
            color: #333;
            width: 100%;
            cursor: pointer;

            span {
              float: right;
            }
          }

          .el-col {
            text-align: left;

            &:nth-child(1) {
              width: 92%;
              padding: 0 0.3rem;

              .svg-inline--fa.fa-w-12 {
                margin-right: 0.2rem;
                color: #a1a1a1;
              }
            }

            &:nth-child(2) {
              width: 1%;

              .svg-inline--fa.fa-w-16 {
                color: #1fbaf3;
              }
            }

            &:nth-child(2n) {
              text-align: right;
              padding-left: 0.5rem;
            }
          }

          &:nth-child(1) {
            background-color: #e3e3e3 !important;

            span {
              float: right;
            }

            .el-col {
              &:nth-child(1) {
                padding: 0 1rem 0 3rem;
              }
            }
          }

          &:nth-child(2n+1) {
            background-color: #cee1e7;
          }
        }
      }
    }

    /deep/ .el-pagination {
      padding-top: 1rem;

      button {
        border: 0.03rem #b9bfc1 solid;
        padding: 0.1rem;
        color: #39a9d9;
        // display: inline-block;
        font-size: 0.3rem;
        // min-width: 0.6rem;
        height: 0.7rem;
      }

      .btn-prev {
        border-radius: 0.1rem 0 0 0.1rem;
      }

      .btn-next {
        border-left: none;
        border-radius: 0 0.1rem 0.1rem 0;
      }

      .el-pager li {
        // padding: 0 0.25rem;
        border: 0.03rem #b9bfc1 solid;
        border-left: none;
        font-size: 0.3rem;
        height: 0.7rem;
        line-height: 0.7rem;
      }

      .el-pager li.active {
        color: #fff;
        background-color: #32a6d8;
      }
    }
  }
}
</style>
