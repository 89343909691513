<template>
  <div class="content_all" id="scrollmenu">
    <banner :type="BannerType"></banner>
    <div class="heart_wrap">
      <div class="img_wrap"><img :src="ThreeColObj.backgroudImg"/></div>
      <div class="more_wrap">
        <p>{{$t('home.REACH') }}</p>
        <p>{{$t('home.Provide') }}</p>
        <el-button type="primary" @click="toAir">{{$t('home.Learn') }} >></el-button>
      </div>
      <div class="solve_wrap">
        <p class="altitle" ref="threeTitle">{{ThreeColObj.title}}</p>
        <div class="solve_box_wrap">
          <div class="solve_box" ref="threeEachCol" v-for="(item,index) in ThreeColObj.ThreeColList" :key="index">
            <p ref="threeSubtitle">{{item.title}}<br/>{{item.SubtitleBotom}}</p>
            <p class="conentWrap"><a :href="item.contentLink" target="_blank">{{item.content}}</a></p>
            <div class="link_wrap">
              <a :href="item.button1Link" target="_blank" ref="threeCotent">
                <p>{{item.button1}}</p>
              </a>
              <a :href="item.button2Link" target="_blank" ref="threeCotent">
                <p>{{item.button2}}</p>
              </a>
              <a :href="item.button3Link" target="_blank" ref="threeCotent">
                <p>{{item.button3}}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="case_wrap">
        <p class="altitle">{{$t('home.success') }}</p>
        <div class="case_content">
          <a :href="item.link" target="_blank" v-for="(item,index) in successList" :key="index">
            <img :src="item.image"/>
          </a>
        </div>
        <p><a @click="Tosuces()" class="cursor">{{$t('home.stories') }}>></a></p>
      </div>
    </div>
    <div
      class="product_wrap"
      ref="scrollWrap"
    >

      <div class="product_show">
        <p class="altitle" ref="scrollTitle">{{scrollRow.title}}</p>
        <el-row class="product_row" type="flex" justify="space-between">
          <el-col
            :span="4"
            v-for="(item,index) in scrollRow.topRow"
            :key="index"
            :class="index==curCardIndex?'boderCol':''"
            @click.native="clickCol(item,index)">
            <img :src="item.imgUrl"/>
            <span>{{item.title}}</span>
          </el-col>
        </el-row>
        <div class="product_bottom">
          <div class="arrow_wrap">
            <div class="arrow left_arrow" @click="goLeft()"><i></i></div>
            <div class="arrow right_arrow" @click="goRight()"><i></i></div>
          </div>
          <el-row class="product_row" ref="moveProduct" type="flex" justify="space-between">
            <el-col :span="4" v-for="(item,index) in scrollRow.bottomRow" :key="index">
              <el-card shadow="always">
                <a :href="item.productLink" target="_blank" class="cursor">
                  <img :src="item.productImage"/>
                  <p>{{item.productName}}</p>
                </a>
              </el-card>
            </el-col>
          </el-row>

        </div>
      </div>
    </div>
    <div class="news_wrap">
      <div class="news_heart">
        <p class="altitle">{{$t('home.Industry') }}</p>
        <el-row type="flex" justify="space-around">
          <el-col :span="3" v-for="(item,index) in ThreeColObj.ThreeNewsList" :key="index" ref="threeNesEachCol">
            <el-card shadow="always">
              <img :src="item.titleImage"/>
              <div class="new_indro">
                <a :href="item.newsLink" target="_blank">
                  <span class="news_title" ref="threeNesSubtitle">{{item.title}}</span>
                </a>
                <span class="content" ref="threeNesCotent">{{item.content}}</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="aboutUs">
      <img :src="chartData.bgImg" ref="zheXianImg"/>
      <div class="aboutUs_heart">
        <div class="aboutUs_text">
          <p class="altitle" ref="zheXianTitle">{{chartData.titleObj.content}}</p>
          <el-row class="aboutUs_span">
            <span ref="zheXianContent">{{chartData.SEtitleObj.content}}</span>
          </el-row>
          <el-row>
            <el-button type="primary" ref="zheXianBnt">
              <!-- <a :href="chartData.bntObj.contentLink" target="_blank">了解更多 >></a> -->
              <a @click="getMore" target="_blank">{{$t('home.Learn') }}  >></a>
            </el-button>
          </el-row>
        </div>
        <div class="zheXian_ch" ref="zheXianWrap">
          <zheXian></zheXian>
        </div>
      </div>
    </div>
    <div class="totop_wrap">
      <Totop></Totop>
    </div>
  </div>
</template>
<script>
import banner from "../../components/homePages/banner.vue";
import zheXian from '../../components/zheXian/zheXian.vue'
import Totop from '../../components/toTop/toTop.vue'
import { Swiper, Slide } from 'vue-swiper-component'
import axios from '../../api/axios'
import Cookies from "js-cookie";

export default {
   inject:['reload'],
  components: {
    banner,
    zheXian,
    Totop,
    Swiper,
    Slide
  },
  props: {
    msg: String
  },
  data () {
    return {
      produce1:[],
      produce2:[],
      produce3:[],
      produce4:[],
      produce5:[],
      curCardIndex:0,
      BannerType: 'homepage',
      currentDate: new Date(),
      tableData: {},
      currentPage: 1, // 当前页码
      total: 10, // 总条数
      pageSize: 2, // 每页的数据条数
      BanerNews: {
        newsBgColor: '#1fbaf3',
        newsHeight: '70px',
        newsNum: '3', // 新闻显示数量
        defaultNum: '3', // 默认显示数量
        fontColor: '#fff'
      },
      BannerList: [
        {
          width: '100%',
          height: '390px',
          imgPath: require('../../assets/try.gif'),
          imgLink: 'https://www.baidu.com/'
        },
        {
          width: '100%',
          height: '390px',
          imgPath: require('../../assets/banner3.jpg'),
          imgLink: 'https://www.baidu.com/'
        },
        {
          width: '100%',
          height: '390px',
          imgPath: require('../../assets/banner2.jpg'),
          imgLink: 'https://www.baidu.com/'
        }
      ],
      // 三列文字
      ThreeColObj: {
        height: '600px',
        everyColwidth: '700px',
        backgroudImg: require('../../assets/homepage.jpg'),
        title: this.$t('home.solution'),
        titleColor: '#2c3e50',
        titlePostion: 'center',
        SubtitleSize: '19px',
        SubtitleColor: '#2c3e50',
        contentSize: '14px',
        contentColor: '#2c3e50',
        ThreeColList: [
          {
            SubtitleTop: '端到云',
            SubtitleBotom: '一站式零基础开发模式',
            content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端”开发，让零基础的企业也能迅速进入智能领域。',
            contentLink: 'https://www.baidu.com/',
            imgUrl: require('../../assets/new1.png')
          },
          {
            SubtitleTop: '端到云',
            SubtitleBotom: '一站式零基础开发模式',
            content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端”开发，让零基础的企业也能迅速进入智能领域。',
            contentLink: 'https://www.baidu.com/',
            imgUrl: require('../../assets/new1.png')
          },
          {
            SubtitleTop: '端到云',
            SubtitleBotom: '一站式零基础开发模式',
            content: '锐骐物联为全球物联网开发者打造物联网智能平台，连接消费者、制造品牌、OEM厂商和零售连锁的智能化需求，为客户解决开发成本高、周期长、难度大等难题，一站式实现“联网模块+云+电脑/手机控制端”开发，让零基础的企业也能迅速进入智能领域。',
            contentLink: 'https://www.baidu.com/',
            imgUrl: require('../../assets/new1.png')
          }
        ],
        ThreeNewsList: [
          {
            SubtitleTop: '一种基于LoRa@的人员定位解决方案，为化工园区提供智能安全保障机制',
            content: '结合LoRao和低功耗蓝牙定位技术的化工企业人员及资产定位系统解决方案，克服 传统室内定位方案设备、施工成本高、难 维护、难管理的难题，解决化工园区普遍 存在的人员位置不可知、人员监管系统效 率低、无可视化智慧监管平台，危险区域 难管控等问题 ',
            contentLink: 'https://www.baidu.com/',
            imgUrl: require('../../assets/new1.png')
          },
          {
            SubtitleTop: '2一种基于LoRa@的人员定位解决方案，为化工园区提供智能安全保障机制',
            content: '结合LoRao和低功耗蓝牙定位技术的化工企业人员及资产定位系统解决方案，克服 传统室内定位方案设备、施工成本高、难 维护、难管理的难题，解决化工园区普遍 存在的人员位置不可知、人员监管系统效 率低、无可视化智慧监管平台，危险区域 难管控等问题 ',
            contentLink: 'https://www.baidu.com/',
            imgUrl: require('../../assets/new1.png')
          },
          {
            SubtitleTop: '3一种基于LoRa@的人员定位解决方案，为化工园区提供智能安全保障机制',
            content: '结合LoRao和低功耗蓝牙定位技术的化工企业人员及资产定位系统解决方案，克服 传统室内定位方案设备、施工成本高、难 维护、难管理的难题，解决化工园区普遍 存在的人员位置不可知、人员监管系统效 率低、无可视化智慧监管平台，危险区域 难管控等问题 ',
            contentLink: 'https://www.baidu.com/',
            imgUrl: require('../../assets/new1.png')
          }
        ]
      },
      // 折线图
      chartData: {
        height: '436px', // 背景图高度
        bgImg: require('../../assets/u502.jpg'), // 背景图
        titleObj: { // 一级标题
          content: this.$t('home.About'),
          titleColor: '#2c3e50',
          titlePostion: 'center' // left，center,right
        },
        SEtitleObj: { // 二级标题
          content: this.$t('home.Focusing'),
          SEtitleColor: '#2c3e50',
          SEtitlePostion: 'center' // left，center,right
        },
        bntObj: {
          bntColor: 'rgba(31, 186, 243, 1)',
          bntFontColor: '#FFF', // 按钮文字颜色
          bntFontSize: '15px', // 按钮文字大小
          // contentLink: 'https://www.baidu.com/s?wd=156%2B&ie=UTF-8' // 按钮链接
        }
      },
      scrollRow: {
        backImgHeight: '580px',
        backImg: '',
        title: this.$t('home.Empowerment'),
        titleColor: '#2c3e50',
        titlePosition: 'center',
        topRow: [
          { imgUrl: require('../../assets/u399.png'), title: this.$t('home.Smart'), type: 1 },
          { imgUrl: require('../../assets/u400.png'), title: this.$t('home.Vehicle'), type: 2 },
          { imgUrl: require('../../assets/u401.png'), title: this.$t('home.City'), type: 3 },
          { imgUrl: require('../../assets/u402.png'), title: this.$t('home.protection'), type: 4 },
          { imgUrl: require('../../assets/u403.png'), title: this.$t('home.AIoT'), type: 5 }
        ],
        bottomRow: [
          { imgUrl: require('../../assets/3pro.png'), title: 'T1-BOX', link: 'https://www.baidu.com/' },
          { imgUrl: require('../../assets/2pro.png'), title: 'T2-BOX', link: 'https://www.baidu.com/' },
          { imgUrl: require('../../assets/1pro.png'), title: 'T3-BOX', link: 'https://www.baidu.com/' },
          { imgUrl: require('../../assets/2pro.png'), title: 'T4-BOX', link: 'https://www.baidu.com/' },
          { imgUrl: require('../../assets/1pro.png'), title: 'T5-BOX', link: 'https://www.baidu.com/' },
          { imgUrl: require('../../assets/1pro.png'), title: 'T6-BOX', link: 'https://www.baidu.com/' },
          { imgUrl: require('../../assets/4pro.png'), title: 'T7-BOX', link: 'https://www.baidu.com/' }
        ]
      },
      // 成功案例
      successList: [
        require('../../assets/case1.png'),
        require('../../assets/case1.png'),
        require('../../assets/case1.png'),
        require('../../assets/case1.png'),
        require('../../assets/case1.png'),
        require('../../assets/case1.png')]
    }
  },
  created () {
  },
  mounted () {
    // this.getBanerNews()
    this.setZhexian()
    this.setScorll()
    this.getHomepageModules()
    this.getSuccCases()
    // this.getBanner()
    this.getHomepageNews()
    this.getProductList(1)
    this.getProductList(2)
    this.getProductList(3)
    this.getProductList(4)
    this.getProductList(5)
    this.getWxCode()
  },
  methods: {
    toAir(){
      this.$router.push({ path: "/Airlan" });
    },
    getMore(){
       this.$router.push({ path: "/reach" });
    },
    Tosuces(){
      this.$router.push({path:"/cooperation",query:{succs:true}});
    },
    getHomepageModules () {
      var url = '/reach/portal/api/homepage/getHomepageModules'
      axios.post(url,{},{
        params:{
          language:localStorage.getItem('locale')
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.ThreeColObj.ThreeColList = response.data.data
        }
      })
    },
    addPointOffset () {
      this.$refs.zheXianWrap.style.left = '-46%'
    },
    getSuccCases () {
      var url = '/reach/portal/api/homepage/getSuccCases'
      axios.post(url,{},{
         params:{
          language: localStorage.getItem("locale")
         }
      }).then(response => {
       console.log(response)
        if (response.data.code === 1) {
          this.successList = response.data.data
        }
      })
    },
    getProductList (e) {
      var url = '/reach/portal/api/homepage/getProductList'
      axios.post(url, {}, {
        params: {
          type: e,
          language: localStorage.getItem("locale")
        }
      }).then(response => {
        if (response.data.code === 1) {
          if(e==1){
            this.produce1= response.data.data
          }
          else if(e==2){
            this.produce2= response.data.data
          }
          else if(e==3){
            this.produce3= response.data.data
          }
          else if(e==4){
            this.produce4= response.data.data
          }
          else if(e==5){
            this.produce5= response.data.data
          }
        }
      this.scrollRow.bottomRow=[];
      this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce1).concat(this.produce2).concat(this.produce3).concat(this.produce4).concat(this.produce5)
      })
    },
    /*
    getBanerNews () {
      this.$refs.bnNews.style.backgroundColor = this.BanerNews.newsBgColor
      this.$refs.bnNews.style.height = this.BanerNews.newsHeight
      this.$refs.bnNews.style.color = this.BanerNews.fontColor
    },
    getBanner () {
      // 放请求赋值this.imgList后
      var url = '/reach/portal/api/homepage/getBannerList'
      axios.post(url, {}, {
        params: {
          type: 'homepage'
        }
      }).then(response => {
        if (response.data.code) {
          this.BannerList = response.data.data
        }
      })
      this.BannerList.forEach(element => {
        element.width = element.width ? element.width : '100%'
        element.height = element.height ? element.height : '390px'
        // element.adImage = imageUrl + '/reach/portal/image/' + element.adImage
      })
    }, */
    getHomepageNews () {
      var url = '/reach/portal/api/homepage/getNewsList'
      axios.post(url,{},{
		params: {
			language: localStorage.getItem("locale")}
		}).then(response => {
        if (response.data.code === 1) {
          console.log(response.data.data)
          this.ThreeColObj.ThreeNewsList = response.data.data
        }
      })
    },
    setZhexian () {
      this.$refs.zheXianImg.style.height = this.chartData.height
      this.$refs.zheXianTitle.style.color = this.chartData.titleObj.titleColor
      this.$refs.zheXianTitle.style.textAlign = this.chartData.titleObj.titlePostion
      this.$refs.zheXianContent.style.color = this.chartData.SEtitleObj.SEtitleColor
      this.$refs.zheXianContent.style.textAlign = this.chartData.SEtitleObj.SEtitlePostion
      this.$refs.zheXianBnt.$el.style.backgroundColor = this.chartData.bntObj.bntColor
      this.$refs.zheXianBnt.$el.style.color = this.chartData.bntObj.bntFontColor
      this.$refs.zheXianBnt.$el.style.fontSize = this.chartData.bntObj.bntFontSize
    },
    // 滚动区
    goLeft () {
      var that=this;
      that.$refs.moveProduct.$el.classList.add("moveAnim2")
      setTimeout(function (){
          that.$refs.moveProduct.$el.classList.remove("moveAnim2")
      }, 1000);
      var obj = this.scrollRow.bottomRow.splice(this.scrollRow.bottomRow.length - 1, 1)
      this.scrollRow.bottomRow.splice(0, 0, obj[0])
      this.curCardIndex=parseInt(this.scrollRow.bottomRow[0].productType)-1;
    },
    goRight () {
       var that=this;
      that.$refs.moveProduct.$el.classList.add("moveAnim")
      setTimeout(function (){
          that.$refs.moveProduct.$el.classList.remove("moveAnim")
      }, 1000);
     var obj = this.scrollRow.bottomRow.splice(0, 1)
      this.scrollRow.bottomRow.splice(this.scrollRow.bottomRow.length, 0, obj[0])
      this.curCardIndex=parseInt(this.scrollRow.bottomRow[0].productType)-1;
    },
    clickCol (row, index) {
      this.curCardIndex=index;
    /*   var url = '/reach/portal/api/homepage/getProductList'
      axios.post(url, {}, {
        params: {
          type: row.type
        }
      }).then(response => {
        if (response.data.code === 1) {
          this.scrollRow.bottomRow = response.data.data
        }
      }) */
        this.scrollRow.bottomRow=[];
        if(index==0){
          // this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce1,this.produce2,this.produce3,this.produce4,this.produce5)
           this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce1).concat(this.produce2).concat(this.produce3).concat(this.produce4).concat(this.produce5)
        }
        else if(index==1){
          this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce2).concat(this.produce3).concat(this.produce4).concat(this.produce5).concat(this.produce1)
        }
        else if(index==2){
          this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce3).concat(this.produce4).concat(this.produce5).concat(this.produce1).concat(this.produce2)
        }
        else if(index==3){
          this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce4).concat(this.produce5).concat(this.produce1).concat(this.produce2).concat(this.produce3)
        }
        else if(index==4){
          this.scrollRow.bottomRow=this.scrollRow.bottomRow.concat(this.produce5).concat(this.produce1).concat(this.produce2).concat(this.produce3).concat(this.produce4)
        }
    },
    setScorll () {
      this.$refs.scrollWrap.style.height = this.scrollRow.backImgHeight
      this.$refs.scrollWrap.style.background = this.scrollRow.backImg == '' ? '#f2f2f2' : ''
      this.$refs.scrollTitle.style.color = this.scrollRow.titleColor
      this.$refs.scrollTitle.style.textAlign = this.scrollRow.titlePosition
    },
    /*
    * 微信回调获取code
    * */

    checkTokennn () {
          var url = '/reach/portal/api/user/getUserInfo'
          //区别刷新时候结束，不用往下执行
          axios.get(url).then(response => {
              if(response.data.code==1){
                  if(response.data.data.username){

                  }
                  else{
                     this.$router.push({ path: "/wXuserDetail" });
                  }
              }
              else{
              }
          })
    },
    getWxCode: function () {
      var that = this
      var currentUrl = window.location.href
      // 获取#/之前的字符串
      currentUrl = currentUrl.split('#')[0]
      var paramsStr = currentUrl.includes('?')? currentUrl.split('?')[1] : '' // 获取?之后的参数字符串
      var paramList = paramsStr.split('&') // 参数字符串分割为数组
      var params = {}
      // 遍历数组，拿到json对象
      for (var i = 0; i < paramList.length; i++) {
        params[paramList[i].split('=')[0]] = paramList[i].split('=')[1]
      }
      var code = params.code
      if (code !== undefined) {
        this.wxCode = code
        console.log(this.wxCode)
        var url = '/reach/portal/api/user/login'
        axios.post(url, {}, {
          params: {
            wxCode: this.wxCode
          }
        }).then(response => {
          if (response.data.code === 1) {
            // console.log(response.data.data)
            Cookies.set('token', response.data.data.token)
            window.localStorage.setItem('usertoken', response.data.data.token)
            window.localStorage.setItem('curretUser', response.data.data.nickname)
            window.localStorage.setItem('wxCode', this.wxCode)
            if (response.data.data.username) {//不为null为true
              window.localStorage.setItem('curretUser', response.data.data.username)
            }
            window.location.href = 'https://www.reachxm.com'
            that.checkTokennn();
          } else {
            this.$message.error('登录失败')
          }
        })
      }
      this.$router.push({path: '/'})
    },
    async checkToken (token) {
      var that = this
      axios.post('/f/eshopping/eshoppinglogin/tokencheck', {}, {
        params: {
          eshoppingtoken: token
        }
      }).then(res => {
        switch (res.data.statuscode) {
          case 0:
            window.localStorage.setItem('curretUser', res.data.username)
            window.localStorage.setItem('usertoken', token)
            that.$message.success(this.$t('login.successful'))
            // that.$router.push({ path: "/homePage" });
            that.$router.back(-1)
            break
          case 1:
            that.$msgboxx(that, '提示', '登陆已失效1')
            setTimeout(function () {
              that.$router.push({ path: '/loginn' })
            }, 2000)
            break
          case 2:
            that.$msgboxx(that, '提示', '登陆已失效2')
            setTimeout(function () {
              that.$router.push({ path: '/loginn' })
            }, 2000)
            break
          case 3:
            that.$msgboxx(that, '提示', '登陆已失效3')
            break
          default:
            break
        }
      })
    }
  }
}
</script>
<style scoped>
  .heart .el-col-8 {
    width: 18.33333%;
  }

  .content_all .el-button--primary {
    width: 2.8rem;
    /* height: 0.63rem; */
    /* line-height: 0.4rem; */
    border-radius: 0.15rem;
    background-color: rgba(31, 186, 243, 1);
  }

  .product_row {
    padding: 0 28px 0 27px;
    .el-card__body{
      padding:0 20px !important;
    }
  }
  .moveAnim{
    animation: moveAnim 0.5s;
  }
  .moveAnim2{
    animation: moveAnim2 0.5s;
  }
@keyframes  moveAnim{
    0%{transform:translateX(1rem);}
    100%{transform:translateX(0);}
}
@keyframes  moveAnim2{
    0%{transform:translateX(-1rem);}
    100%{transform:translateX(0);}
}
  .product_row .el-col-4 {
    margin-right: 0.78rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .boderCol:after {
    width: 3.22rem;
    height: 0.13rem;
    background-color: #ffde5d;
    content: "";
    position: absolute;
    left: -0.2rem;
    top: 3.21rem;
    z-index: 10;
  }

  .product_row .el-card {
    width: 3rem;
    height: 3rem;;
    border-radius: 0.15rem;
    box-shadow: 0 0 0.2rem rgb(0 0 0 / 25%) !important;
  }

  .product_row .el-card__body {
    padding: 0px;
    color: #a4a4a4;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .news_heart p {
    padding: 15px 0 19px 0;
  }

  .news_heart .el-card {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  .news_heart .el-card__body {
    padding: 0;
    height: 100% !important;
  }

  .new_indro {
    padding: 0 8px 0 10px;
    text-align: left;
    height: 3rem;
  }

  .news_heart .new_indro .content {
    font-size: 0.25rem;
    color: #827c7c;
    overflow: auto;
    height: 75%;
    display: inline-block;
  }

  .news_heart .new_indro .news_title {
    display: block;
    font-weight: 600;
    font-size: 0.28rem;
    color: #3c3b3c;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0.1rem;
  }

  .news_heart img {
    width: 100%;
    height: 2.6rem;
  }

  /*  .aboutUs_heart .el-button--primary {
    width: 133px;
    height: 33px;
    line-height: 1px;
    border-radius: 6px;
    margin-top: 26px;
  } */
</style>
<style scoped lang="less">

  .el-button {
    border: 0.05rem solid #DCDFE6;
    font-size: 0.26rem;

    a {
      color: #fff;
    }
  }

  .content_all {
    padding-bottom: 10px;
    position: relative;
  }

  .content_all .totop_wrap {
    position: fixed;
    bottom: 37px;
    right: 36px;
    z-index: 20;
  }

  .altitle {
    font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 250;
    font-style: normal;
    font-size: 0.4rem;
    margin-top: 0.5rem;
  }

  .lunch_wrap {
    width: 100%;
    background-color: #1fbaf3;
    margin-top: 1.5rem;
  }

  .lunch_top {
    width: 16rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.6rem;
  }

  .lunch_top p {
    text-align: left;
    flex: 1;
    font-size: 0.24rem;
    color: #ffffff;
  }

  .lunch_top p:nth-child(2) {
    flex: 1.4;
  }

  .lunch_top p:nth-child(2) {
    flex: 1.5;
    text-align: center;
  }

  .lunch_bottom {
    .wh_slide {
      height: auto;
      /* img{
        width: 100%;
        height: 100%;
      } */
    }

    /deep/ .wh_indicator {
      bottom: 0.2rem;

      .wh_indicator_item {
        width: 1rem;
        height: 0.08rem;
        margin: 0rem 0.1rem;
        background: #fff;
        border-radius: 0;
      }

      .wh_show_bgcolor {
        background: #efcd5c;
      }
    }
  }

  .heart_wrap {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .more_wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 10.5rem;
    height: 2.8rem;
    justify-content: space-between;
    font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 250;
    font-size: 0.5rem;
    margin-top: 0.7rem;
    z-index: 6;
    position: relative;
  }

  .more_wrap span {
    font-weight: 600;
  }

  .more_wrap p:nth-child(2) {
    font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 250;
    font-style: normal;
    font-size: 0.25rem;
  }

  .img_wrap {
    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 100%;
    // height: 15rem;
    z-index: 2;
  }

  .img_wrap img {
    width: 100%;
    height: 100%;
  }

  .solve_wrap {
    width: 100%;
    position: relative;
    z-index: 6;
    text-align: center;
    margin-top: 1rem;
  }

  .solve_box_wrap {
    display: flex;
    justify-content: space-around;
    max-width: 18rem;
    margin: 0 auto;
  }

  .solve_box {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1rem 0;
    padding-right: 1rem;
    max-width: 6rem;
    .conentWrap{
      height: 2.7rem;
      a{
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 7;
          // overflow: hidden;
      }
    }
  }
  .solve_box a {
    color: #000;
  }

  .solve_box p {
    font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 250;
    font-style: normal;
    font-size: 0.29rem;
  }

  .solve_box p:nth-child(1) {
    font-size: 0.41rem;
    font-weight: 500;
    // width: 5rem;
    margin-bottom: 0.42rem;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }

  .link_wrap {
    text-align: right;
    margin-top: 0.7rem;
    a{
      &:hover{
        color: #129bd7;
      }
    }
  }

  .link_wrap p {
    font-size: 0.28rem !important;
  }

  .case_wrap {
    width: 100%;
    position: relative;
    z-index: 6;
    text-align: center;
    a{
      &:hover{
        color: #169bd5;
      }
    }
  }

  .case_content {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    flex-wrap: wrap;
    a{
      width: 15%;
       margin-top: 0.5rem;
      img {
        width: 100%;
        &:hover{
          transform:scale(1);
          animation: anim 1s;
        }
    }
    }
  }

@keyframes  anim{
    0%{transform:scale(1)}
    100%{transform:scale(1.15)}
}

  .case_wrap p a {
    display: inline-block;
    margin-top:0.3rem;
    font-family: "微软雅黑 Light", "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 250;
    font-style: normal;
    color: #333333;
    font-size: 0.28rem;
  }

  .product_wrap {
    position: relative;
    width: 100%;
    //background-image: "";
    //background: #f2f2f2;
    margin-top: 0.5rem;
    //background-image: url(../../assets/back.jpg);
  }

  .product_show {
    width: 100%;
    height: 8rem;
    text-align: center;
    padding: 0.8rem 0;
    position: relative;
    z-index: 10;

    .el-row {
      margin: 0.7rem auto;
      width: 18.3rem;
      font-size: 0.3rem;
      position: relative;
      z-index: 50;
    }

    img {
      width: 1.7rem;
      height: 1.7rem;
      margin-bottom: 0.45rem;
    }
  }

  .product_bottom {
    position: relative;
    height: 5rem;
    z-index: 5;

    .el-row {
      overflow: hidden;
      border-top: 0.1rem solid #cccccc;
      padding: 0.5rem 0;

      a {
        color: #2c3e50;
      }
    }

  }

  .arrow_wrap {
    width: 97%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    padding-left: 0.5rem;
    top: -6%;
    z-index: 2;

    .arrow {
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      background-color: #cecece;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        border: solid #909090;
        display: inline-block;
        padding: 0.17rem;
        border-width: 0 0.1rem 0.1rem 0;
      }
    }
  }

  .left_arrow i {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .right_arrow i {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .news_wrap {
    width: 100%;
    height: 8rem;
    padding: 1rem 0;
    position: relative;
    z-index: 20;

    .news_heart {
      width: 100%;

      .el-row {
        margin: 0 auto;
        width: 20rem;

        .el-col-3 {
          width: 5rem;
          height: 6.35rem;
        }
      }
    }
  }

  .aboutUs {
    width: 100%;
    position: relative;
    height: 8rem;
    overflow: hidden;
  }

  .aboutUs img {
    width: 107%;
    position: absolute;
    top: -0.932292rem;
    left: -1rem;
    height: 9.3rem; //500px
    z-index: 1;
    opacity: 0.6;
    /* filter: blur(3px); */
  }

  /*
  .aboutUs:before {
    background-size: cover;
    background: url('../../assets/u502.jpg') no-repeat;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -1 可以当背景
    -webkit-filter: blur(3px);
    filter: blur(3px);
  } */

  .aboutUs_text {
    position: relative;
    z-index: 6;
  }

  .aboutUs_span {
    margin: 0.25rem 0;
    font-size: 0.3rem;
  }

  .aboutUs_heart {
    height: 100%;
    width: 10rem;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding-top: 9px;
    z-index: 10;
  }

  .zheXian_ch {
    position: absolute;
    bottom: 19%;
    left: -25%;
    z-index: 3;
  }
</style>
